.shape1Copy {
  position: relative;
  width: 620px;
  height: 1121px;
}
.hex {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
}
.bgChild {
  left: calc(50% - 738px);
  border-radius: 50%;
  background: radial-gradient(50% 50%at 50% 50%, rgba(3, 133, 228, 0.5), rgba(3, 133, 228, 0));
  width: 990.59px;
  height: 988.39px;
  z-index: 1;
}
.bg,
.bgChild,
.bgItem {
  margin: 0 !important;
  position: absolute;
  top: 0;
}
.bgItem {
  left: calc(50% - 252.59px);
  border-radius: 50%;
  background: radial-gradient(50% 50%at 50% 50%, rgba(205, 34, 198, 0.45), rgba(205, 34, 198, 0));
  width: 990.59px;
  height: 988.39px;
  z-index: 2;
}
.bg {
  width: 100%;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  z-index: 0;
}
.logoIcon {
  position: relative;
  width: 28px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.headerTokenChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.selfTokenIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.b {
  position: relative;
  line-height: 16px;
}
.selfTokenParent {
  position: absolute;
  height: 62.5%;
  width: 54.62%;
  top: 18.75%;
  right: 33.46%;
  bottom: 18.75%;
  left: 11.92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.headerTokenItem {
  position: absolute;
  top: 0;
  left: 98px;
  width: 32px;
  height: 32px;
}
.headerToken {
  position: relative;
  width: 130px;
  height: 32px;
}
.metamaskIcon {
  position: absolute;
  top: 6px;
  left: 11px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.xc9dpf9 {
  position: absolute;
  top: 8px;
  left: 39px;
  line-height: 16px;
}
.rectangle79Stroke1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  height: 32px;
}
.buttonHeader {
  position: relative;
  border-radius: var(--br-21xl);
  width: 130px;
  height: 32px;
  font-size: var(--selfkeyid-desktop-cta-header-size);
}
.buttons,
.headerMobile {
  flex-direction: row;
  align-items: center;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-xs);
}
.headerMobile {
  align-self: stretch;
  display: none;
  justify-content: space-between;
  font-size: var(--desktop-small-size);
}
.logoIcon1 {
  position: relative;
  width: 164px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.networkButtonIcon {
  position: relative;
  width: 40px;
  height: 40px;
  display: none;
}
.selfTokenIcon1 {
  position: absolute;
  height: 60%;
  width: 14.81%;
  top: 20%;
  right: 70.37%;
  bottom: 20%;
  left: 14.81%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.b1 {
  position: absolute;
  top: 30%;
  left: 34.57%;
  line-height: 16px;
}
.ellipse118Stroke,
.vectorStrokeIcon {
  position: absolute;
  height: 100%;
  width: 24.69%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 75.31%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorStrokeIcon {
  height: 50%;
  width: 6.79%;
  top: 25%;
  right: 8.64%;
  bottom: 25%;
  left: 84.57%;
}
.headerToken1 {
  position: relative;
  width: 162px;
  height: 40px;
}
.metamaskIcon1 {
  position: absolute;
  top: 8px;
  left: 24px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.button {
  position: absolute;
  top: 30%;
  left: 21.54%;
  line-height: 16px;
}
.buttonHeader1 {
  position: relative;
  border-radius: var(--br-21xl);
  width: 260px;
  height: 40px;
}
.buttons1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-base);
  z-index: 1;
}
.logoIcon2,
.logoIcon3 {
  position: absolute;
  margin: 0 !important;
  height: 90.91%;
  width: 11.84%;
  top: 4.55%;
  right: 88.16%;
  bottom: 4.55%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: none;
  z-index: 2;
}
.logoIcon3 {
  width: 11.35%;
  right: 88.65%;
  z-index: 3;
}
.headerDesktop {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.nft1Icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.dNft {
  position: relative;
  width: 300px;
  height: 312px;
}
.transparentBgIcon {
  position: relative;
  width: 320px;
  height: 240px;
  z-index: 0;
}
.nftAddress,
.xfc0023fdd54bcc9b13c3 {
  position: relative;
  line-height: 20px;
}
.xfc0023fdd54bcc9b13c3 {
  color: var(--white);
}
.address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.b3,
.issuedOn {
  position: relative;
  line-height: 24px;
}
.b3 {
  color: var(--white);
}
.issued {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--selfkeyorg-desktop-paragraph-size);
}
.button2 {
  position: relative;
  font-size: var(--selfkeyid-desktop-cta-header-size);
  line-height: 16px;
  font-family: var(--desktop-small);
  color: var(--white);
  text-align: left;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-13xl);
  background-color: transparent;
  border-radius: var(--br-21xl);
  background: linear-gradient(93.17deg, #2da1f8, #cd22c6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button1:hover,
.button5:hover {
  box-shadow: 0 0 1px #0385e4;
}
.txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.copyIcon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.copyParent {
  flex-direction: column;
  padding: 0 0 36px;
  align-items: flex-start;
  gap: 52px;
}
.copyParent,
.transparentBgParent,
.txtParent {
  display: flex;
  justify-content: flex-start;
}
.txtParent {
  margin: 0 !important;
  position: absolute;
  top: 32px;
  left: 32px;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-9xs);
  z-index: 1;
}
.transparentBgParent {
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--selfkeyid-bright-orange);
}
.backIcon {
  position: relative;
  width: 16px;
  height: 16px;
}
.button3 {
  border-radius: var(--br-21xl);
  background-color: var(--black-transparent-20);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-13xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.dicordDecorationIcon {
  position: absolute;
  top: 0;
  left: calc(50% - 150px);
  width: 88px;
  height: 88px;
}
.dicordDecorationIcon1 {
  position: absolute;
  top: 250px;
  left: calc(50% + 16px);
  width: 64px;
  height: 64px;
}
.dicordDecorationIcon2 {
  position: absolute;
  top: 1px;
  left: calc(50% + 103px);
  width: 40px;
  height: 40px;
}
.glassBgChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-base);
  background: linear-gradient(100.17deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05));
  backdrop-filter: blur(24px);
}
.glassBgInner,
.glassBgItem {
  position: absolute;
  border-radius: 0 0 var(--br-base) 0;
  width: 160px;
  height: 155px;
}
.glassBgItem {
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(3, 133, 228, 0) 22.4%, rgba(3, 133, 228, 0.15));
}
.glassBgInner {
  top: 155px;
  left: 160px;
  background: linear-gradient(135deg, rgba(205, 34, 198, 0) 30.73%, rgba(205, 34, 198, 0.1));
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.glassBg {
  position: absolute;
  top: 14px;
  left: calc(50% - 130px);
  width: 280px;
  height: 276px;
}
.joinSelfkeyDao {
  margin: 0;
}
.joinSelfkeyDaoContainer {
  position: absolute;
  top: 130px;
  left: calc(50% - 104px);
  line-height: 32px;
}
.button5 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-13xl);
  background-color: transparent;
  position: absolute;
  top: 218px;
  left: calc(50% - 65px);
  border-radius: var(--br-21xl);
  background: linear-gradient(93.17deg, #2da1f8, #cd22c6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dicordIcon {
  position: absolute;
  top: 46px;
  left: calc(50% - 30px);
  width: 80px;
  height: 60px;
}
.discord {
  position: relative;
  width: 300px;
  height: 314px;
  text-align: center;
  font-size: var(--mobile-h2-size);
  font-family: var(--selfkeyid-desktop-h3);
}
.dNftParent {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 48px;
}
.backArrowIcon {
  position: relative;
  width: 28px;
  height: 16px;
}
.button7 {
  border-radius: var(--br-21xl);
  background-color: var(--black-transparent-20);
  width: 72px;
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.transparentBgIcon1 {
  position: relative;
  width: 384px;
  height: 384px;
  z-index: 0;
}
.copyGroup,
.issued1 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.issued1 {
  display: none;
  gap: var(--gap-9xs);
}
.copyGroup {
  display: flex;
  padding: 0 0 4px;
  gap: var(--gap-41xl);
}
.button8,
.txtGroup {
  display: flex;
  flex-direction: row;
}
.txtGroup {
  align-items: flex-end;
  justify-content: flex-start;
  gap: 49px;
}
.button8 {
  border-radius: var(--br-21xl);
  background: linear-gradient(93.17deg, #2da1f8, #cd22c6);
  padding: var(--padding-base) var(--padding-21xl);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--white);
}
.button10:hover,
.button8:hover {
  box-shadow: 0 0 16px #0385e4;
}
.frameContainer,
.transparentBgGroup {
  display: flex;
  justify-content: flex-start;
}
.frameContainer {
  margin: 0 !important;
  position: absolute;
  top: 40px;
  left: 40px;
  flex-direction: column;
  align-items: center;
  gap: 128px;
  z-index: 1;
}
.transparentBgGroup {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  gap: var(--gap-3xs);
}
.frameChild {
  position: relative;
  width: 210px;
  height: 371px;
}
.buttonParent,
.frameGroup,
.rectangleParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.buttonParent,
.frameGroup {
  align-self: stretch;
  justify-content: space-between;
}
.frameGroup {
  flex: 1;
  padding: 0 137px;
}
.buttonParent {
  font-size: var(--selfkeyorg-desktop-paragraph-size);
  color: var(--selfkeyid-bright-orange);
}
.headerMobileParent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-45xl);
  max-width: 1216px;
}
.dicordDecorationIcon3 {
  position: absolute;
  top: 0;
  left: calc(50% - 431.5px);
  width: 148px;
  height: 148px;
}
.dicordDecorationIcon4 {
  position: absolute;
  top: 118px;
  left: calc(50% + 60.5px);
  width: 112px;
  height: 112px;
}
.dicordDecorationIcon5,
.glassBg1 {
  position: absolute;
  top: 0;
  left: calc(50% + 367.5px);
  width: 64px;
  height: 64px;
}
.glassBg1 {
  top: 25px;
  left: calc(50% - 384.5px);
  width: 800px;
  height: 155px;
}
.joinSelfkeyDaoContainer1 {
  position: absolute;
  top: 66px;
  left: calc(50% - 204.5px);
  line-height: 36px;
}
.dicordIcon1 {
  position: absolute;
  top: 65px;
  left: calc(50% - 344.5px);
  width: 100px;
  height: 75px;
}
.button10 {
  position: absolute;
  top: 79px;
  left: calc(50% + 175px);
  border-radius: var(--br-21xl);
  background: linear-gradient(93.17deg, #2da1f8, #cd22c6);
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-21xl);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: var(--selfkeyorg-desktop-paragraph-size);
  font-family: var(--desktop-small);
}
.discord1 {
  position: relative;
  width: 863px;
  height: 230px;
  font-size: var(--selfkeyid-desktop-h3-size);
  font-family: var(--selfkeyid-desktop-h3);
}
.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-xl) 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-141xl);
  z-index: 1;
  text-align: left;
  color: var(--white);
}
.frameInner {
  position: relative;
  width: 1608px;
  height: 122.62px;
  z-index: 0;
}
.frameChild1 {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: calc(50% - 600px);
  background: linear-gradient(90deg, rgba(3, 133, 228, 0), #0385e4 50%, rgba(3, 133, 228, 0));
  width: 1200px;
  height: 0.98px;
  z-index: 1;
}
.vectorParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  gap: var(--gap-3xs);
  z-index: 0;
}
.logoIcon4,
.logoIcon5 {
  position: relative;
  height: 36px;
}
.logoIcon4 {
  width: 131.91px;
  overflow: hidden;
  flex-shrink: 0;
}
.logoIcon5 {
  width: 136px;
  display: none;
}
.facebookIcon,
.redditIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.redditIcon {
  display: none;
}
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-21xl);
}
.privacyPolicyTermsContainer {
  position: relative;
  text-decoration: underline;
  line-height: 16px;
  color: var(--white);
}
.all,
.small {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.all {
  margin: 0 !important;
  position: absolute;
  top: 80px;
  left: calc(50% - 140px);
  justify-content: flex-start;
  gap: var(--gap-37xl);
  z-index: 1;
}
.backgroundChild,
.footerDesktop {
  background-color: var(--selfkeyid-black);
}
.footerDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 2;
  font-size: var(--desktop-small-size);
}
.backgroundChild {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 330px;
}
.backgroundInner,
.backgroundItem {
  position: absolute;
  top: 0;
  left: calc(50% - 180px);
  width: 360px;
  height: 69.6px;
}
.backgroundInner {
  left: calc(50% - 160px);
  background: linear-gradient(90deg, rgba(3, 133, 228, 0), #0385e4 50%, rgba(3, 133, 228, 0));
  width: 320px;
  height: 1.16px;
}
.background {
  position: absolute;
  z-index: 0;
}
.logoIcon6,
.redditIcon1 {
  position: relative;
  width: 117.25px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.redditIcon1 {
  width: 20px;
  height: 20px;
  display: none;
}
.social1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}
.bySelfkey1 {
  position: relative;
  line-height: 14px;
}
.all1 {
  margin: 0 !important;
  position: absolute;
  top: 40px;
  left: calc(50% - 98px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  z-index: 1;
}
.footerMobile,
.mySelfkeyId {
  position: relative;
  flex-direction: column;
}
.footerMobile {
  display: none;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  z-index: 3;
  font-size: var(--mobile-small-size);
}
.mySelfkeyId {
  background-color: var(--selfkeyid-black);
  width: 100%;
  height: 1600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--selfkeyid-desktop-cta-header-size);
  color: var(--grey);
  font-family: var(--desktop-small);
}
@media screen and (max-width: 1200px) {
  .buttonParent,
  .rectangleParent {
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .hex {
    flex-direction: row;
    gap: var(--gap-81xl);
    align-items: flex-start;
    justify-content: center;
  }
  .frameGroup {
    padding-left: var(--padding-21xl);
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .headerMobile {
    display: flex;
  }
  .headerDesktop {
    display: none;
  }
  .dNftParent {
    display: flex;
  }
  .frameGroup {
    flex: unset;
    align-self: stretch;
  }
  .buttonParent {
    display: none;
    flex-direction: column;
  }
  .discord1,
  .footerDesktop {
    display: none;
  }
  .footerMobile {
    display: flex;
  }
}
