.scrollable-image-container {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.scrollable-image {
  width: 100%;
  height: 100%;
  overflow: visible;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS devices */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.scrollable-image::-webkit-scrollbar {
  width: 0; /* Hide scrollbar in Chrome and Safari */
  display: none;
}

.scrollable-image img {
  max-width: 100%;
  display: block;
}
