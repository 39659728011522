html {
  background-color: rgb(3, 12, 30);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

._37_FJ {
  position: relative !important;
  transform: translate(-50%) !important;
  top: 50% !important;
  margin-top: 20vh;
}
